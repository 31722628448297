/* stylelint-disable selector-id-pattern */
/* stylelint-disable no-descending-specificity */
@import 'https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz@1,8..144&family=Roboto:wght@300;400;500;700;900&display=swap';

:root {
  /* COLORS */
  --blue: #2273ba;
  --blue-navy: #0b3b60;
  --blue-light: #f1f9fd;
  --error: #dd524c;
  --error-light: #f0a9a7;
  --error-dark: #7b302d;
  --gray: #e6e6e6;
  --gray-light: #f7f7f7;
  --gray-medium: #aeaeae;
  --gray-darkish: #595d64;
  --gray-dark: #595959;
  --gray-darkest: #2d2926;
  --teal: #28bfe0;

  /* primary */
  --btn-border-primary: #ffbe5f;
  --btn-border-primary-hover: #ffbe5f;
  --btn-bg-primary: #ffbe5f;
  --btn-bg-primary-hover: #ffd495;
  --btn-text-primary: #0b3b60;

  /* primary DISABLED */
  --btn-disabled-border-primary: #e6e6e6;
  --btn-disabled-bg-primary: #e6e6e6;
  --btn-disabled-text-primary: #aeaeae;

  /* secondary */
  --btn-border-secondary: #0b3b60;
  --btn-border-secondary-hover: #07253c;
  --btn-bg-secondary: transparent;
  --btn-bg-secondary-hover: #ffffff;
  --btn-text-secondary: #0b3b60;
  --btn-text-secondary-hover: #07253c;

  /* secondary DISABLED */
  --btn-disabled-border-secondary: #aeaeae;
  --btn-disabled-bg-secondary: #ffffff;
  --btn-disabled-text-secondary: #aeaeae;

  /* tertiary */
  --btn-bg-tertiary: #ffbe5f;
  --btn-text-tertiary: #0b3b60;

  /* tertiary DISABLED */
  --btn-disabled-bg-tertiary: #e6e6e6;
  --btn-disabled-text-tertiary: #aeaeae;
}

.fsForm,
.fsBody input,
#dvFastForms * {
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: 'Roboto', sans-serif !important;
}

.fsBody.fsEmbed {
  border-radius: 8px;
  padding: 0 !important;
}

.fsForm,
#dvFastForms form .ff-form-main {
  background: white;
  border: 1px solid var(--gray) !important;
  border-radius: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 68px 24px 28px !important;
}

.ff-form-main,
.ff-item-row,
.ff-footer-group {
  overflow: visible !important;
}

.fsSectionText {
  padding-left: 0 !important;
}

.ff-section-col {
  margin-bottom: 24px;
}

.ff-item-row {
  margin-bottom: 20px !important;
}

.ff-group-row {
  margin: 0 !important;
}

/* INPUT FIELDS */

input[type='text'].fsField,
input[type='email'].fsField,
input[type='tel'],
select.fsField,
.ff-input-type.ff-type-text {
  border-color: var(--gray) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  color: var(--gray-dark) !important;
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  height: 53px !important;
  line-height: 19px !important;
  padding: 16px !important;
}

/* V4 Fields */
input[type='text'],
input[type='email'].fsFieldEmail,
select {
  border-color: var(--gray) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  color: var(--gray-dark) !important;
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  height: 53px !important;
  line-height: 19px !important;
  padding: 16px !important;
}

textarea {
  border-color: var(--gray) !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  color: var(--gray-dark) !important;
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  line-height: 19px !important;
  padding: 16px !important;
}

input[type='email']:focus,
textarea:focus,
select:focus {
  box-shadow: 0 0 0 2px var(--blue) !important;
}

select,
select.fsField {
  padding: 16px 20px 16px 16px !important;
}

.fsForm input[type='text']:focus,
.fsForm input[type='number']:focus,
.fsForm input[type='checkbox']:focus:before,
.fsForm input[type='radio']:focus:before,
.fsForm input[type='password']:focus,
.fsForm textarea:focus,
input[type='text'].fsField:not(:read-only):focus,
input[type='tel']:focus,
select.fsField:focus,
select:focus {
  box-shadow: 0 0 0 2px var(--blue) !important;
}

.ff-input-type.ff-type-text:focus-visible,
.ff-input-type.ff-type-text:focus {
  outline-color: var(--blue);
}

.ff-col-2.ff-field-col:focus-visible {
  outline: none;
}

.fsCreditcardFieldContainer {
  margin-bottom: 24px;
}

.fsForm .fsFieldRow .fsReadOnly input[type='text'] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.fsFieldRow .fsFieldCell {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fsField {
  border-radius: 4px !important;
}

.fsField:focus {
  box-shadow: 0 0 0 2px var(--blue) !important;
}

span.fsLabel {
  display: none !important;
}

.fsBody .fsSubField.fsFieldZip {
  width: 100% !important;
}

.fsSubField {
  /* position: relative; */
  display: flex;
  flex-direction: column;
}

.fsRow .fsFieldFocused:not(.fsValidationError).fsSpan100 {
  margin: 0 !important;
  margin-bottom: 20px !important;
  padding: 0 !important;
}

.fsRow.fsFieldRow:has(div.fsHidden) {
  margin-bottom: 0 !important;
}

/* LABELS */

.fsSubField > label {
  order: -1;
}

.fsSubField.fsFieldState {
  margin-bottom: 10px;
}

.fsSubField.fsFieldZip > label {
  /* height: 24px !important;
  line-height: 21px;
  margin-bottom: 8px !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important; */
}

label.fsSupporting,
label.fsSupportingSubLabel,
.fsLabel,
.ff-label {
  color: var(--gray-dark) !important;
  display: block;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}

.ff-label-col {
  align-items: flex-start;
  display: flex;
  padding: 0 !important;
}

.fsSupporting.fsRequiredLabel:after {
  content: '*';
}

label.hidden {
  display: none !important;
}

label.fsOptionLabel {
  background: transparent !important;
  border: none !important;
}

/* BUTTONS */

.fsSubmit {
  margin-top: 0 !important;
}

.fsForm .fsSubmit .fsSubmitButton,
.ff-btn-submit {
  align-items: center !important;
  appearance: none !important;
  background: var(--btn-bg-primary) !important;
  border-radius: 999px !important;
  box-shadow: var(--btn-border-primary) 0 0 0 1px !important;
  color: var(--btn-text-primary) !important;
  display: inline-flex !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: auto !important;
  justify-content: center !important;
  line-height: 1 !important;
  padding: 16px 40px !important;
  text-align: center !important;
  transition: all 0.2s ease-in-out !important;
  white-space: pre !important;
}

.fsForm .fsSubmit .fsSubmitButton:hover,
.fsForm .fsSubmit .fsSubmitButton:focus,
.fsForm .fsSubmit .fsSubmitButton:active,
.ff-btn-submit:hover,
.ff-btn-submit:focus,
.ff-btn-submit:active {
  background: var(--btn-bg-primary-hover) !important;
  box-shadow: var(--btn-border-primary-hover) 0 0 0 1px !important;
  color: var(--btn-text-primary) !important;
}

.fsFileUploadButton {
  align-items: center !important;
  appearance: none !important;
  background: var(--btn-bg-secondary) !important;
  border-radius: 999px !important;
  box-shadow: var(--btn-border-secondary) 0 0 0 1px !important;
  color: var(--btn-text-secondary) !important;
  cursor: pointer;
  display: inline-flex !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: auto !important;
  justify-content: center !important;
  line-height: 1 !important;
  padding: 12px 30px !important;
  text-align: center !important;
  transition: all 0.2s ease-in-out !important;
  white-space: pre !important;
}

.fsFileUploadButton:hover,
.fsFileUploadButton:focus,
.fsFileUploadButton:active {
  background: var(--btn-bg-secondary-hover) !important;
  box-shadow: inset var(--btn-border-secondary) 0 0 0 2px !important;
  color: var(--btn-text-secondary-hover) !important;
}

.btnDiv {
  text-align: left !important;
}

.btnDiv input {
  margin-left: 0 !important;
}

.ff-submit-btn,
.ff-item-row.ff-footer-row,
.ff-footer-group {
  margin: 0 !important;
  padding: 0 !important;
}

.footnoteDiv {
  display: flex;
}

/* .fsForm input:focus {
  box-shadow: none !important;
} */

.fsSubFieldGroup {
  /* margin-top: 20px !important; */
  margin-top: 0;
}

/* .fsSubFieldGroup .fsSubField:first-child {
  margin-bottom: 40px !important;
} */

/* CHECKBOXES */
[fs-field-type='checkbox'] label.fsOptionLabel {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
  margin: 4px 0;
  width: fit-content !important;
}

.fsRowBody input[type='checkbox'] {
  height: 24px !important;
  top: 0 !important;
  width: 24px !important;
}

.fsRowBody input[type='checkbox']:before {
  background-color: white !important;
  border: 1px solid var(--gray) !important;
  border-radius: 4px !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  width: 24px !important;
}

.fsRowBody input[type='checkbox']:checked:before {
  background-color: var(--blue-navy) !important;
  background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16.7045 4.15347C17.034 4.4045 17.0976 4.87509 16.8466 5.20457L8.84657 15.7046C8.71541 15.8767 8.51627 15.9838 8.30033 15.9983C8.08439 16.0129 7.87271 15.9334 7.71967 15.7804L3.21967 11.2804C2.92678 10.9875 2.92678 10.5126 3.21967 10.2197C3.51256 9.92682 3.98744 9.92682 4.28033 10.2197L8.17351 14.1129L15.6534 4.29551C15.9045 3.96603 16.3751 3.90243 16.7045 4.15347Z' fill='white'/></svg>") !important;
  background-size: contain !important;
  border: 1px solid var(--blue-navy) !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  width: 24px !important;
}

/* V4 Form checkbox */
.fsOptionLabel {
  color: var(--gray-darkish) !important;
  display: block;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}


input[type='checkbox'] {
  height: 24px !important;
  top: 0 !important;
  width: 24px !important;
}

input[type='checkbox']:before {
  background-color: white !important;
  border: 1px solid var(--gray) !important;
  border-radius: 4px !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  /* position: absolute !important; */
  width: 24px !important;
}

input[type='checkbox']:checked:before {
  background-color: var(--blue-navy) !important;
  background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16.7045 4.15347C17.034 4.4045 17.0976 4.87509 16.8466 5.20457L8.84657 15.7046C8.71541 15.8767 8.51627 15.9838 8.30033 15.9983C8.08439 16.0129 7.87271 15.9334 7.71967 15.7804L3.21967 11.2804C2.92678 10.9875 2.92678 10.5126 3.21967 10.2197C3.51256 9.92682 3.98744 9.92682 4.28033 10.2197L8.17351 14.1129L15.6534 4.29551C15.9045 3.96603 16.3751 3.90243 16.7045 4.15347Z' fill='white'/></svg>") !important;
  background-size: contain !important;
  border: 1px solid var(--blue-navy) !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  /* position: absolute !important; */
  width: 24px !important;
}

/* RADIOS */

[fs-field-type='radio'] label.fsOptionLabel {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
  margin: 4px 0;
  width: fit-content !important;
}

.fsRowBody {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
}

.fsRowBody input[type='radio'] {
  border-radius: 100% !important;
  height: 24px !important;
  top: 0 !important;
  width: 24px !important;
}

.fsRowBody input[type='radio']:before {
  background-color: white !important;
  border: 1px solid var(--gray) !important;
  border-radius: 100%;
  content: '';
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  width: 24px !important;
}

.fsRowBody input[type='radio']:checked:before {
  background-color: var(--blue-navy) !important;
  border: 1px solid var(--blue-navy) !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  width: 24px !important;
}

.fsRowBody input[type='radio']:checked:after {
  border: 2px solid white !important;
  border-radius: 100% !important;
  content: '';
  height: 16px !important;
  left: 50% !important;
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: 16px !important;
}

/* V4 radio */

input[type='radio'] {
  border-radius: 100% !important;
  height: 24px !important;
  top: 0 !important;
  width: 24px !important;
}

input[type='radio']:before {
  background-color: white !important;
  border: 1px solid var(--gray) !important;
  border-radius: 100%;
  content: '';
  height: 24px !important;
  left: 0 !important;
  /* position: absolute !important; */
  width: 24px !important;
}

input[type='radio']:checked:before {
  background-color: var(--blue-navy) !important;
  border: 1px solid var(--blue-navy) !important;
  content: '';
  height: 24px !important;
  left: 0 !important;
  /* position: absolute !important; */
  width: 24px !important;
}

input[type='radio']:checked:after {
  border: 2px solid white !important;
  border-radius: 100% !important;
  content: '';
  height: 16px !important;
  left: 50% !important;
  /* position: absolute !important; */
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: 16px !important;
}

.fsRequiredMarker {
  margin-left: 2.5px !important;
  font-size: 16px;
  color: var(--gray-darkish) !important;
}

.ui-datepicker-trigger {
  display: none !important;
}

@media screen and (min-width: 641px) {
  /* .fsSubFieldGroup .fsSubField:first-child {
    margin-bottom: 0 !important;
  } */

  .fsSubFieldGroup .fsSubField .fsFieldAddress {
    margin-bottom: 10px !important;
  }

  .fsRow.fsFieldRow {
    align-items: flex-end;
    display: flex !important;
    flex-direction: row !important;
  }

}
@media screen and (min-width: 481px) {
  .fsFieldRow .fsFieldCell:first-child:not(.fsHidden) ~ .fsFieldCell:nth-child(2) {
    margin-left: 4% !important;
  }
}
@media screen and (min-width: 414px) {
  .fsSubmitButton {
    width: auto !important;
  }
}

.submission-msg img,
.submission-msg svg,
.submission-msg video,
.submission-msg canvas,
.submission-msg audio,
.submission-msg iframe,
.submission-msg embed,
.submission-msg object {
  display: unset;
  vertical-align: unset;
}

/* Needs to be more specific than FormStack styles, but less specific than ours */
body .hidden {
  height: unset;
  left: unset;
  overflow: unset;
  position: unset;
  width: unset;
}

body .fsBody .fsSectionHeader {
  background-color: transparent;
}

body .formErrorBack {
  display: none;
}

body .fsBody .fsHidden,
body .fsBody .fsWorkflowHidden {
  display: none !important;
}
